
.arrow_part span {
  display: block;
  width: 1em;
  height: 1em;
  border-bottom: 4px solid var(--dark);
  border-right: 4px solid var(--dark);
  margin: -1em;
  animation: animate 1.5s infinite;
}

.arrow_part span:nth-child(2) {
  animation-delay: -0.3s;
}

.arrow_part span:nth-child(3) {
  animation-delay: -0.6s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
