#about .about-content {
  display: flex;
  align-items: center;
}

#about .profile-pic {
  height: 240px;
  border-radius: 50%;
  margin-right: 50px;
}

#about p {
  color: var(--dark);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-top: 30px;
  line-height: 1.2;
}

@media (max-width: 500px) {
  #about .about-content {
    flex-direction: column;
  }

  #about .profile-pic {
    margin-right: 0;
  }
}

#about h1:hover{
    color: var(--light-pink);
    animation: up 1.5s 1;
}

#about a:link {
 font-weight: bold;
 color: var(--light-pink);
}
