/* Modified from Dali on Codepen*/

.background-animation {
  position: relative;
  height: 400px;
  width: 700px;
}

.section {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 5%;
  max-width: 130%;
  line-height: 1;
  font-family: 'Courier Prime', monospace;
  font-size: 1.2em;
  opacity: 0.5;
  padding-top: 24px;
  font-weight: normal;
  color: #141021;
}
.lg-paper {
  position: absolute;
  width: 60px;
  height: 65px;
  background: #fff;
  border: 2px solid #eee;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}
.sm-paper {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 2px solid #eee;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}
.paper-1 {
  top: 55px;
  left: 225px;
}
.bg {
  position: absolute;
  z-index: 9;
  top: 105px;
  left: 188px;
}
.paper-2 {
  top: 95px;
  left: 175px;
}
.paper-3 {
  top: 55px;
  left: 525px;
}
.table {
  position: absolute;
  top: 235px;
  width: 100%;
  height: 10px;
  background: var(--dark-brown);
}
.link {
  color: #fff;
}
.fa {
  font-size: 2em;
  text-align: center;
  transition: 0.7s;
}
.fa:hover {
  animation: rotate 1.5s 1;
}

.fa-envelope {
  position: absolute;
  top: 15px;
  left: 15px;
  color: var(--light-pink);
}
.fa-linkedin {
  position: absolute;
  top: 15px;
  left: 15px;
  color: var(--light-green);
}
.fa-github-square {
  position: absolute;
  top: 3.2px;
  left: 3.2px;
  color: #f8b899;
}
.bowl {
  position: absolute;
  top: -25px;
  left: 450px;
  width: 50px;
  height: 25px;
  background: #f8b899;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
}
.bowl-2 {
  position: absolute;
  top: -25px;
  left: 500px;
  width: 50px;
  height: 25px;
  background: var(--dark-brown);
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
}
.bowl-2::after {
  position: absolute;
  left: 2px;
  width: 50px;
  height: 25px;
  background: var(--dark-brown);
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  content: "";
}
.bowl-3 {
  position: absolute;
  top: -25px;
  left: 84px;
  width: 50px;
  height: 25px;
  background: var(--light-pink);
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
}
.bowl-3::after {
  position: absolute;
  left: 10px;
  width: 50px;
  height: 25px;
  background: var(--light-pink);
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  content: "";
}
.stem {
  position: absolute;
  top: -100px;
  left: 24px;
  width: 3px;
  height: 100px;
  background: #deb887;
}
.leaf-1 {
  position: absolute;
  left: 2px;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  background: #abd9b5;
  animation: fly 1s infinite alternate ease-in-out;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
}
.leaf-2 {
  position: absolute;
  top: 25px;
  left: -20px;
  width: 20px;
  height: 20px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #abd9b5;
  animation: fly 1s infinite alternate ease-in-out;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
}
.leaf-3 {
  position: absolute;
  top: 50px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  background: #abd9b5;
  animation: fly 1s infinite alternate ease-in-out;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
}
.leaf-4 {
  position: absolute;
  top: 75px;
  left: -20px;
  width: 20px;
  height: 20px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #abd9b5;
  animation: fly 1s infinite alternate ease-in-out;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
}
.leaf-5 {
  position: absolute;
  top: -14px;
  left: 35px;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  background: #abd9b5;
  animation: fly 2s infinite alternate ease-in-out;
  transform: rotate(30deg);
}
.leaf-6 {
  position: absolute;
  top: -14px;
  left: 15px;
  width: 20px;
  height: 20px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #abd9b5;
  animation: fly 2s infinite alternate ease-in-out;
  transform: rotate(-40deg);
}
.leaf-7 {
  position: absolute;
  top: -18px;
  left: 30px;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  background: #abd9b5;
  animation: fly 2s infinite alternate ease-in-out;
  transform: rotate(20deg);
}
.leaf-8 {
  position: absolute;
  top: -18px;
  left: 20px;
  width: 20px;
  height: 20px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #abd9b5;
  animation: fly 3s infinite alternate ease-in-out;
  transform: rotate(30deg);
}
.leaf-9 {
  position: absolute;
  top: -23px;
  left: 25px;
  width: 20px;
  height: 20px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #abd9b5;
  animation: fly 2s infinite alternate ease-in-out;
  transform: rotate(70deg);
}
.notebook {
  position: absolute;
  top: -80px;
  left: 300px;
  width: 120px;
  height: 75px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #fff;
  border: 2px solid #eee;
}
.notebook::after {
  position: absolute;
  top: 70px;
  left: -10px;
  width: 140px;
  height: 10px;
  background: #fff;
  border: 2px solid #eee;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  content: "";
}
.apple {
  position: absolute;
  top: 30px;
  left: 45px;
  width: 30px;
  height: 25px;
  background: #eee;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.apple::before {
  position: absolute;
  top: -10px;
  left: 15px;
  width: 3px;
  height: 15px;
  background: #eee;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  content: "";
}
.apple::after {
  position: absolute;
  top: -10px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #eee;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 15px;
  content: "";
}
.book-1 {
  position: absolute;
  top: -15px;
  left: 205px;
  width: 70px;
  height: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 2px solid #abd9b5;
  border-right: 0;
  background: #fff;
}
.book-2 {
  position: absolute;
  top: -22px;
  left: 205px;
  width: 70px;
  height: 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #f8b899;
}
.book-3 {
  position: absolute;
  top: -18px;
  left: 215px;
  width: 70px;
  height: 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #f8b899;
}

.coffee {
  position: absolute;
  top: -55px;
  left: 220px;
  width: 50px;
  height: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: var(--dark-brown);
}
.coffee::after {
  position: absolute;
  top: 3px;
  left: -15px;
  width: 22px;
  height: 18px;
  border-bottom-right-radius: 30px;
  border: 4px solid var(--dark-brown);
  border-top: 0;
  box-sizing: border-box;
  transform: rotate(-270deg);
  content: "";
}
.smoke {
  position: absolute;
  top: -25px;
  left: 20px;
  width: 5px;
  height: 17px;
  background: #eee;
  border-radius: 10px;
  opacity: 0.9;
  animation: fly 1s infinite alternate ease-in-out;
}
.smoke::after {
  position: absolute;
  top: -7px;
  left: 5px;
  width: 5px;
  height: 15px;
  background: #eee;
  border-radius: 10px;
  opacity: 0.9;
  content: "";
}
.box {
  position: absolute;
  top: -60px;
  left: 150px;
  width: 40px;
  height: 60px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
}
.box-2 {
  position: absolute;
  top: -60px;
  left: 580px;
  width: 40px;
  height: 60px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
}
.circle {
  position: absolute;
  top: 32px;
  left: 7px;
  width: 26px;
  height: 26px;
  background: #eee;
  border-radius: 50%;
}
.circle::before {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 12px;
  height: 13px;
  background: #eee;
  border: solid 3px #fff;
  border-radius: 10px;
  content: "";
  animation: beat 0.5s infinite;
}
.circle-2 {
  position: absolute;
  top: 5px;
  left: 8px;
  width: 24px;
  height: 24px;
}
.circle-2::before {
  width: 10px;
  height: 10px;
}
.circle-3 {
  position: absolute;
  top: 5px;
  left: 8px;
  width: 24px;
  height: 24px;
}
.circle-3::before {
  width: 10px;
  height: 10px;
}
.me {
  position: absolute;
  top: 90px;
  left: 315px;
}
.me-body {
  position: absolute;
  top: 50px;
  width: 100px;
  height: 65px;
  border-radius: 50%;
  background: #f8b899;
  animation: own 1s infinite linear;
}
.me-head {
  position: absolute;
  top: -17px;
  left: 25px;
  width: 45px;
  height: 65px;
  animation: own 1s infinite linear;
  z-index: 1;
}
.face {
  position: absolute;
  width: 45px;
  height: 65px;
  border-radius: 25px;
  background: #fff;
}
.hair {
  position: absolute;
  left: -10px;
  width: 60px;
  height: 75px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #444;
}
.hair-front-1 {
  position: absolute;
  left: -10px;
  width: 40px;
  height: 40px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background: #444;
  animation: up 3s infinite;
}
.hair-front-2 {
  position: absolute;
  left: 25px;
  width: 30px;
  height: 35px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #444;
  animation: up 3s infinite;
}
.eye-left {
  position: absolute;
  top: 40px;
  left: 12px;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 10px;
  animation: blink 1s infinite alternate ease-in-out;
}
.eye-left::before {
  position: absolute;
  top: -5px;
  left: -3px;
  width: 5px;
  height: 2px;
  background: #666;
  border-radius: 10px;
  content: "";
  animation: up 3s infinite;
}
.eye-right {
  position: absolute;
  top: 40px;
  left: 32px;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 10px;
  animation: blink 1s infinite alternate ease-in-out;
}
.eye-right::before {
  position: absolute;
  top: -5px;
  left: -3px;
  width: 5px;
  height: 2px;
  background: #666;
  border-radius: 10px;
  content: "";
  animation: up 3s infinite;
}
.mouth {
  position: absolute;
  top: 55px;
  left: 19px;
  width: 10px;
  height: 3px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 2px solid #f8b899;
  border-top: 0;
  box-sizing: border-box;
  animation: smile 5s infinite;
}

@-moz-keyframes own {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes own {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes own {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes up {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes up {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-o-keyframes up {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes smile {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes smile {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@-o-keyframes smile {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@-moz-keyframes blink {
  0% {
    background-color: #000;
  }
  25% {
    background-color: #000;
  }
  50% {
    background-color: #000;
  }
  75% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes blink {
  0% {
    background-color: #000;
  }
  25% {
    background-color: #000;
  }
  50% {
    background-color: #000;
  }
  75% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}
@-o-keyframes blink {
  0% {
    background-color: #000;
  }
  25% {
    background-color: #000;
  }
  50% {
    background-color: #000;
  }
  75% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}
@-moz-keyframes fly {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}
@-webkit-keyframes fly {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}
@-o-keyframes fly {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}
@-moz-keyframes beat {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}
@-webkit-keyframes beat {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}
@-o-keyframes beat {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}
@-moz-keyframes own {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes own {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes own {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes own {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes up {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes up {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-o-keyframes up {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes up {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes smile {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@-webkit-keyframes smile {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@-o-keyframes smile {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes smile {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleX(1);
  }
}
@-moz-keyframes blink {
  0% {
    background-color: #000;
  }
  25% {
    background-color: #000;
  }
  50% {
    background-color: #000;
  }
  75% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes blink {
  0% {
    background-color: #000;
  }
  25% {
    background-color: #000;
  }
  50% {
    background-color: #000;
  }
  75% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}
@-o-keyframes blink {
  0% {
    background-color: #000;
  }
  25% {
    background-color: #000;
  }
  50% {
    background-color: #000;
  }
  75% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes blink {
  0% {
    background-color: #000;
  }
  25% {
    background-color: #000;
  }
  50% {
    background-color: #000;
  }
  75% {
    background-color: #000;
  }
  100% {
    background-color: #fff;
  }
}
@-moz-keyframes fly {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}
@-webkit-keyframes fly {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}
@-o-keyframes fly {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}
@keyframes fly {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}
@-moz-keyframes beat {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}
@-webkit-keyframes beat {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}
@-o-keyframes beat {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes beat {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleX(1.5);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes rotate {
  50% {
    transform: rotate(-20deg);
  }
}
