@import url("./animation.css");
@import url("./arrow.css");
@import url("../../node_modules/react-typist/dist/Typist.css");

:root {
    --light: #eee2dc;
    --dark: #141021;
    --light-pink: #f8b899;
    --light-green: #abd9b5;
    --dark-brown: #7e6551;
}

.home {
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0,0);
  /* background-image: url(../assets/images/cat2.jpg); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 90vh;
  background-position: 100% 100%;
  /* background-size: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px grey;
}

.home .home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home h1 {
  display: inline-block;
  font-size: 3.95em;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-color: var(--dark);
  text-align: center;
  margin: 0 8px;
  line-height: 120%;
  margin-bottom: 18px;
}

.mobileIntro {
  display: none;
}


.col1 { color: var(--dark); }
.col2 { color: var(--dark-brown); }
.col3 { color: var(--light-green); }
.col4 { color: var(--light-pink); }

#arrow{
    position: relative;
    top: 30%;
}
/* Arrow CSS */
.arrow_part {
    display: inline-block;
    position: absolute;
    margin: 1.5em;
}


@media (max-width: 900px) {
  .home .home-container {
    background-color: rgba(255,255,255,0.7);
    width: fit-content;
    padding: 36px;
  }
  .home .background-animation {
    display: none;
  }
}

@media (max-width: 700px) {
  .home h1 {
    font-size: 70px;
  }
  .home p {
    font-size: 10px;
  }
  .home .textAnimation {
    display: none;
  }
  .home .mobileIntro {
    display:block;
  }

  .home #arrow {
    display: none;
  }
}
