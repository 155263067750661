.App a {
  color: grey;
}

.App a:hover {
  color:#1890ff;
}

.ant-dropdown-menu {
  box-shadow: none !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: transparent !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item:hover a {
  color: #40a9ff;
}

.overlay {
  box-shadow: none;
}

.App .hamburger {
  position: fixed;
  top: 36px;
  right: 36px;
}

.App .nav {
  display: flex;
  justify-content: space-between;
  margin: 16px;
  font-size: 16px;
}

.App .nav a {
  margin: 0 12px;
}

#about, #experience, #projects {
  max-width: 800px;
  margin: auto;
  margin-bottom: 100px;
  padding: 0 16px;
}

/*#projects{
  max-width: 1000px;
  margin: auto;
  margin-bottom: 40px;
  padding: 0 16px;
}*/

#about h1, #experience h1, #projects h1 {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 3em;
  color: var(--dark);
  text-align: left;
  margin: 48px 0;
}

.icons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.icons a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons .icon {
  margin: 0 12px;
}


.bottom-bar {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  max-width: 1000px;
  margin: auto;
}

.bottom-bar .copyright {
  width: auto;
}

.bottom-bar .bottom-text {
  width: auto;
}

.bottom-bar .icons {
  width: auto;
}

.bottom-bar .icons .icon {
  height: 16px;
  margin: 0 4px;
}

@media (max-width: 700px) {
  .bottom-bar Typist {
    display: none;
  }
}
